<template>
  <div class="col-lg-10">
    <div class="d-flex">
      <div class="col">
        <h3>Comparing {{ model.setIdA }} and {{ model.setIdB }}</h3>
        <p>
          Between these two sets, there are
          {{ model.policies.length | formatNumber }} policies.
          <span v-if="filter != 'all'"
            >{{ filteredList.length | formatNumber }} policies match your filter
            ({{
              ((filteredList.length / model.policies.length) * 100)
                | formatNumber
            }}
            %).</span
          >
        </p>
      </div>
      <div
        v-if="compareGroup"
        class="ml-auto d-flex flex-shrink-1 mr-1 align-items-baseline"
      >
        Comparing
        <compare-select-dropdowns
          :group="compareGroup"
          :selectedA.sync="setIdA"
          :selectedB.sync="setIdB"
        ></compare-select-dropdowns>
      </div>
      <div class="flex-shrink-1">
        <b-form-select v-model="filter">
          <b-form-select-option value="all">All</b-form-select-option>
          <b-form-select-option value="both">Only in both</b-form-select-option>
          <b-form-select-option value="left-side"
            >Only left side</b-form-select-option
          >
          <b-form-select-option value="right-side"
            >Only right side</b-form-select-option
          >
          <b-form-select-option value="identical"
            >Only identical</b-form-select-option
          >
          <b-form-select-option value="text-changes"
            >Textual changes</b-form-select-option
          >
          <b-form-select-option value="changes"
            >Any change</b-form-select-option
          >
        </b-form-select>
      </div>
    </div>
    <div>
      <table class="table table-sm table-borderless table-hover">
        <thead>
          <tr>
            <th class="col-4">{{ model.setIdA }}</th>
            <th class="col-2 text-center">Presence</th>
            <th class="col-2 text-center">Other changes</th>
            <th class="col-4 text-right">{{ model.setIdB }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="policy in filteredList" :key="policy.id">
            <td>
              <router-link
                v-if="policy.displayNameA"
                :to="{
                  name: 'admx-set-view',
                  params: {
                    setId: setIdA,
                    policyClass: policy.policyClass,
                    languageCode: languageCode,
                    policyId: policy.id,
                  },
                }"
                title="Goto policy"
                class="pr-1"
                ><b-icon-link45deg
              /></router-link>
              <span v-if="policy.displayNameA">{{ policy.displayNameA }}</span>
              <span v-else class="text-danger font-italic">Missing</span>
            </td>
            <td class="text-center">
              <span v-if="policy.presence == 'both'" class="badge badge-light"
                >Both</span
              >
              <span
                v-else-if="policy.presence == 'only-set-a'"
                class="badge badge-primary"
                >Left only</span
              >
              <span
                v-else-if="policy.presence == 'only-set-b'"
                class="badge badge-secondary"
                >Right only</span
              >
            </td>
            <td class="text-center">
              <span v-if="policy.changedTitle" class="badge badge-info mr-1"
                >Title</span
              >
              <span v-if="policy.changedText" class="badge badge-warning"
                >Text</span
              >
            </td>
            <td class="text-right">
              <span v-if="!policy.displayNameB" class="text-danger font-italic"
                >Missing</span
              >
              <span
                v-else-if="policy.displayNameA != policy.displayNameB"
                class="text-success"
                >{{ policy.displayNameB }}</span
              >
              <span v-else class="text-extra-muted font-italic">No change</span>
              <router-link
                v-if="policy.displayNameB"
                :to="{
                  name: 'admx-set-view',
                  params: {
                    setId: setIdB,
                    policyClass: policy.policyClass,
                    languageCode: languageCode,
                    policyId: policy.id,
                  },
                }"
                title="Goto policy"
                class="pl-1"
                ><b-icon-link45deg
              /></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style>
.text-extra-muted {
  color: rgba(179, 174, 174, 0.466);
}
</style>

<script>
import CompareSelectDropdowns from "../components/CompareSelectDropdowns.vue";
import * as Utility from "../utilities.js";

export default {
  components: {
    CompareSelectDropdowns,
  },
  created() {
    this.fetchGroups();
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
    setIdA() {
      const params = {
        ...this.$route.params,
        setIdA: this.setIdA,
      };

      this.$router.pushSafe({ name: this.$route.name, params });
    },
    setIdB() {
      const params = {
        ...this.$route.params,
        setIdB: this.setIdB,
      };

      this.$router.pushSafe({ name: this.$route.name, params });
    },
  },
  methods: {
    fetchGroups() {
      this.$root.$api.getCompareIndex().then((data) => {
        // Map groupId => {id, sets, ..}
        this.setGroups = data.groups.reduce((prev, current) => {
          prev[current.id] = current;
          return prev;
        }, {});

        // Map setId => groupId
        this.setGroupIdBySetId = {};
        for (const groupId in this.setGroups) {
          const group = this.setGroups[groupId];
          for (const set of group.sets) {
            this.setGroupIdBySetId[set.setId] = groupId;
          }
        }
      });
    },
    fetchData() {
      this.setIdA = this.$route.params.setIdA;
      this.setIdB = this.$route.params.setIdB;

      const modelParams = {
        setIdA: this.setIdA,
        setIdB: this.setIdB,
        languageCode: this.languageCode,
      };

      // Reload the comparison
      if (!Utility.shallowEqual(this.modelParams, modelParams)) {
        this.modelParams = modelParams;
        this.isLoading = true;

        this.$root.$api
          .getCompare(this.setIdA, this.setIdB, this.languageCode)
          .then((data) => {
            // Ensure we're getting a response for our request
            if (!Utility.shallowEqual(this.modelParams, modelParams)) return;

            this.model = data;
            this.isLoading = false;
          });
      }
    },
  },
  computed: {
    languageCode() {
      return this.$route.params.languageCode;
    },
    compareGroup() {
      const groupId = this.setGroupIdBySetId[this.setIdA];
      return this.setGroups[groupId];
    },
    filteredList() {
      switch (this.filter) {
        case "both":
          return this.model.policies.filter((x) => x.presence === "both");
        case "left-side":
          return this.model.policies.filter((x) => x.presence === "only-set-a");
        case "right-side":
          return this.model.policies.filter((x) => x.presence === "only-set-b");
        case "identical":
          return this.model.policies.filter(
            (x) => x.presence === "both" && !x.changedText && !x.changedTitle
          );
        case "text-changes":
          return this.model.policies.filter((x) => x.changedText);
        case "changes":
          return this.model.policies.filter(
            (x) => x.presence != "both" || x.changedText || x.changedTitle
          );
        default:
          return this.model.policies;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      filter: "changes",
      setIdA: "",
      setIdB: "",
      model: {
        setIdA: "",
        setIdB: "",
        policies: [],
      },
      modelParams: {},
      setGroups: {},
      setGroupIdBySetId: {},
    };
  },
};
</script>
